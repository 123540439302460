import React from "react";
import { EducationDataNode } from "../../../models/education-grid-params";
import { naziDataNode, NaziGridProp } from "../../../models/nazi-grid-params";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import '../nazi-grid/nazi-grid.scss'

const ElectionResultsGrid = (props: NaziGridProp) => {
    const round = (number: number) => {
        return Math.round(number * 100) / 100;
    }
    
    const parseElectionResultsData = () => {
        let array = [];
        for (const key in props.data)
            array.push({ name: key, count: round(props.data[key]) });
        array.sort(function (a, b): 1 | -1 | 0 {
            return a.count < b.count ? 1 : -1;
        });
        array = array.slice(0, 16);
        
        let result: naziDataNode[] = [];
        let id = 0;
        for (const el of array) {
            result.push({ id: id++, name: el.name, count: el.count });
            if (props.difference)
                result[result.length - 1]['difference'] = props.difference_data[el.name];
        }
        return result;
    }
    
    const render = () => {
        const data = parseElectionResultsData();
        return (
            <>
                <p className="moving_block__line">Результаты выборов в гос. думу:</p>
                <div>
                    <TableContainer className="scrolling-div" component={ Paper }>
                        <Table sx={ { width: '100%' } } aria-label="simple table">
                            <TableHead>
                                <TableRow sx={ { '& *': { fontWeight: 'bold!important' } } }>
                                    <TableCell sx={ { width: '40%', fontWeight: 'bold' } }>Партия</TableCell>
                                    <TableCell align="right">Голоса</TableCell>
                                    { props.difference ? <TableCell align="right">Голоса 2</TableCell> : null }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { data.map((row: EducationDataNode) => (
                                    <TableRow
                                        key={ 'nazi-table-item' + row.name }
                                        sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
                                    >
                                        <TableCell component="th" scope="row">{ row.name }</TableCell>
                                        <TableCell align="right">{ row.count }</TableCell>
                                        { props.difference ?
                                            row.difference > 0 ?
                                                <TableCell align="right"
                                                           sx={ { color: 'green' } }>{ row.difference }</TableCell>
                                                :
                                                <TableCell align="right"
                                                           sx={ { color: 'red' } }>{ row.difference }</TableCell>
                                            : null }
                                    </TableRow>
                                )) }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </>
        );
    }
    
    return render();
}

export default ElectionResultsGrid;
