export const TableDataNames: any = {
    nazi: 'Национальный состав',
    education: 'Образование',
    pol_vozr: 'Рабочее население',
    count_izbir: 'Число избирательных округов',
    total_izbir_people: 'Численность избирателей',
    extra: '',
    people: 'Население',
    square: 'Площадь',
    migration: 'Миграционный прирост',
    household: 'Средний размер домохозяйств',
    birth: 'Рождаемость',
    agriculture: 'Площадь с/х земель',
    build: 'Строительство',
    trade: 'Торговля',
    services: 'Совокупный объем услуг',
    invest: 'Объем инвестиций',
    success_organizations: 'Финансы предприятий',
    outlay: 'Совокупные доходы',
    salary: 'Средняя зарплата',
    events: 'Малые и средние организации',
    failed_organizations: '',
    revenues: '',
    crimes: 'Преступлений',
    party: 'Партия',
    president_turnout: 'Явка на выборы президента',
    gosdum_turnout: 'Явка на выборы гос. думы',
    guber_turnout: 'Явка на выборы губернатора',
    soviet_republic_turnout: 'Явка на выборы совета республики',
    oblast_dum_turnout: 'Явка на выборы в областную думу',
    oblast_sobr_turnout: 'Явка на выборы в областное собрание',
    election_results: 'Результаты выборов в гос. думу',
};

export const TableDataUnits = {
    nazi: 'чел',
    education: 'на тысячу чел',
    pol_vozr: 'чел',
    count_izbir: 'шт',
    total_izbir_people: 'чел',
    extra: '',
    people: 'чел',
    square: 'га',
    migration: 'чел',
    household: 'чел',
    birth: 'на тысячу чел',
    agriculture: 'га',
    build: 'кв м',
    trade: 'ед',
    services: 'тыс руб',
    invest: 'тыс руб',
    success_organizations: 'ед',
    outlay: 'тыс руб',
    salary: 'руб',
    events: 'ед',
    failed_organizations: '',
    revenues: '',
    crimes: 'шт',
    party: '',
    president_turnout: '%',
    gosdum_turnout: '%',
    guber_turnout: '%',
    soviet_republic_turnout: '%',
    oblast_dum_turnout: '%',
    oblast_sobr_turnout: '%',
    election_results: '',
};

export const TableDataYears = {
    nazi: '2016',
    education: '2016',
    pol_vozr: '',
    count_izbir: '',
    total_izbir_people: '',
    extra: '',
    people: '2020',
    square: '2020',
    migration: '2020',
    household: '2016',
    birth: '2020',
    agriculture: '2020',
    build: '2020',
    trade: '2020',
    services: '2020',
    invest: '2020',
    success_organizations: 'с 2017 по 2020',
    outlay: '2020',
    salary: '2016',
    events: '2020',
    failed_organizations: 'с 2017 по 2020',
    revenues: '2020',
    crimes: '2023',
    party: '',
    president_turnout: '2024',
    gosdum_turnout: '2023',
    guber_turnout: '',
    soviet_republic_turnout: '',
    oblast_dum_turnout: '',
    oblast_sobr_turnout: '',
    election_results: '',
}

export const extraDataNames = {
    '01': 'Промышленное производство',
    '02': 'Сельское хозяйство',
    '03': 'Строительство',
    '04': 'Транспорт',
    '05': 'Торговля',
    '06': 'Услуги',
    '07': 'Инвестиции',
    '08': 'Финансы предприятий',
    '09': 'Потребительские цены',
    '10': 'Цены производителей',
    '11': 'Доходы',
    '12': 'Зарплаты',
    '13': 'Безработица',
    '14': 'Рынок труда',
    '15': 'Малые и средние предприятия',
};

export const extraDataRowsNames = {
    '01': {
        '01': {
            name: 'индекс промышленного производства',
            value: '%',
        },
        '02': {
            name: 'добыча полезных ископаемых',
            value: '%',
        },
        '03': {
            name: 'обрабатывающие производства',
            value: '%',
        },
        '04': {
            name: 'обеспечение электроэнергией, газом и паром, кондиционирование воздуха',
            value: '%',
        },
        '05': {
            name: 'водоснабжение, водоотведение, организация сбора и утилизация отходов',
            value: '%',
        },
        '06': {
            name: 'отгрузка товаров',
            value: 'млн руб',
        },
        '07': {
            name: 'отгрузка рыбы',
            value: 'млн руб',
        },
        '08': {
            name: 'рыба морская живая',
            value: '%',
        },
        '09': {
            name: 'рыба морская свежая или охлажденная',
            value: '%',
        },
        '10': {
            name: 'рыба переработанная и консервированная',
            value: '%',
        },
        '11': {
            name: 'производство электроэнергии',
            value: 'млн кВт/ч',
        },
    },
    '02': {
        '01': {
            name: 'индексы сельского хозяйства',
            value: '%',
        },
    },
    '03': {
        '01': {
            name: 'строительство жилых домов',
            value: 'тыс кв м',
        },
        '02': {
            name: 'добыча полезных ископаемых',
            value: 'млн руб',
        },
    },
    '04': {
        '01': {
            name: 'перевозка грузов и грузооборот',
            value: '%',
        },
    },
    '05': {
        '01': {
            name: 'оборот розничной торговли',
            value: 'млн руб',
        },
        '02': {
            name: 'оборот розничной торговли пищевыми продуктами',
            value: 'млн руб',
        },
        '03': {
            name: 'оборот розничной торговли непродовольственными',
            value: 'млн руб',
        },
        '04': {
            name: 'экспорт',
            value: 'млн долларов США',
        },
        '05': {
            name: 'импорт',
            value: 'млн долларов США',
        },
    },
    '06': {
        '01': {
            name: 'объем платных услуг',
            value: '%',
        },
    },
    '07': {
        '01': {
            name: 'Объем инвестиций в основной капитал',
            value: 'млн руб',
        },
    },
    '08': {
        '01': {
            name: 'доходы прибыльных организаций',
            value: 'млн руб',
        },
        '02': {
            name: 'доля прибыльных организаций',
            value: 'млн руб',
        },
        '03': {
            name: 'доля убыточных организаций',
            value: '%',
        },
        '04': {
            name: 'финансовые результаты',
            value: '%',
        },
        '05': {
            name: 'кредиторская задолженность',
            value: 'млн руб',
        },
        '06': {
            name: 'дебиторская задолженность',
            value: 'млн руб',
        },
    },
    '09': {
        '01': {
            name: 'цены на товары и услуги',
            value: '%',
        },
        '02': {
            name: 'цены на продовольственные товары',
            value: '%',
        },
        '03': {
            name: 'цены на непродовольственные товары',
            value: '%',
        },
        '04': {
            name: 'цены на услуги',
            value: '%',
        },
        '05': {
            name: 'цены на отдельные ЖКХ',
            value: '%',
        },
        '06': {
            name: 'фиксированный набор',
            value: 'руб',
        },
    },
    '10': {
        '01': {
            name: 'индексы цен производителей промышленных товаров',
            value: '%',
        },
        '02': {
            name: 'индексы цен производителей на реализованную сельскохозяйственную продукцию',
            value: '%',
        },
        '03': {
            name: 'индексы цен на продукцию (затраты услуги) инвестиционного назначения',
            value: '%',
        },
        '04': {
            name: 'индексы цен (тарифов) на грузовые перевозки',
            value: '%',
        },
        '05': {
            name: 'цены на первичном и вторичном рынках жилья',
            value: 'руб за 1 кв м',
        },
    },
    '11': {
        '01': {
            name: 'среднедушевые денежные доходы',
            value: 'руб',
        },
        '02': {
            name: 'динамика реальных денежных доходов',
            value: '%',
        },
    },
    '12': {
        '01': {
            name: 'среднемесячная заработная плата',
            value: 'руб',
        },
        '02': {
            name: 'среднемесячная заработная плата работников сельского хозяйства',
            value: 'руб',
        },
        '03': {
            name: 'просроченная задолженность по заработной плате',
            value: 'тыс руб',
        },
        '04': {},
        '05': {
            name: 'численность работников',
            value: 'чел',
        },
    },
    '13': {
        '01': {
            name: 'численность и состав рабочей силы в возрасте 15-72 лет',
            value: '%',
        },
    },
    '14': {
        '01': {
            name: 'выбывшие из организаций',
            value: 'тыс чел',
        },
        '02': {
            name: 'принятые в организации',
            value: 'тыс чел',
        },
        '03': {
            name: 'намеченные к высвобождению',
            value: 'тыс чел',
        },
        '04': {
            name: 'требуемые работники',
            value: 'тыс чел',
        },
        '05': {
            name: 'работавшие неполное рабочее время',
            value: 'тыс чел',
        },
    },
    '15': {
        '01': {
            name: 'численность работников на предприятиях малого и среднего бизнеса',
            value: 'тыс чел',
        },
    },
}