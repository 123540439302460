import React, { useEffect, useRef, useState } from "react";
import mapboxgl from 'mapbox-gl';
import geobuf from "geobuf";
import Pbf from 'pbf';

import './map.scss';
import Api from "../../api/api";
import { popupData } from "../../models/popupData";
import { CrossMenu, TopMenu } from "../index";
import { addingLayerName, getNameByLevel, parseGeojson, setTemperature, toggleBarsLayersIds } from "../../models/utils";
import { extraDataRowsNames } from "../../models/table-data-params";


mapboxgl.accessToken = 'pk.eyJ1IjoidmthYnoiLCJhIjoiY2x4dnR3cnhpMHVpODJrc2FwcmNuOG02aSJ9.TtSQk5NFSldQqP5XGs06hA';


interface MapProps {
    mapRef: any;
    activeMapLayerId: any;
    
    setName: any;
    setRegion: any;
    setCompareName: any;
    setCompareRegion: any;
    setLevel: any;
    setShowType: any;
    setCompareModeMain: any;
    setTemperature: any;
    
    level: number;
    temperature: any;
    temperature_param: any;
    showType: boolean;
    popupMode: boolean;
    popupData: number;
    
    compare_mode: boolean;
    all_data: any[5];
    
    tempMode: boolean;
}


const activeMenuItemStyle = {
    backgroundColor: '#3887be',
    color: '#ffffff'
};


const Map = React.memo((props: MapProps) => {
    const mapContainer = useRef(null);
    const popup = useRef(null);
    const level = useRef(null);
    const clickedPolygon = useRef(null);
    const prevActiveLayer = useRef(getNameByLevel(props.level, props.showType));
    
    const [compare_mode, setCompareMode] = useState(props.compare_mode);
    const [clicked3, setClicked3] = useState(null);
    const [clickedRegion, setClickedRegion] = useState(null);
    const [clicked, setClicked] = useState(null);
    const [activeLayerId, setActiveLayerId] = useState(getNameByLevel(props.level, props.showType));
    const [mapLoaded, setMapLoaded] = useState(false);
    const [name, setName] = useState(null);
    const [info, setInfo] = useState(null);
    let all_data = [];
    
    const loadMap = () => {
        setMapLoaded(true);
        if (mapLoaded) return;
        
        props.mapRef.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/vkabz/clyw0ekbs006a01nu8tbter9o',
            // style: 'mapbox://styles/mapbox/streets-v12',
            center: [37.618423, 55.751244],
            zoom: 5,
        });
        
        props.mapRef.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
        
        props.mapRef.current.on('load', () => {
            function getVisibility(_level: number) {
                if (_level === 5 && (level.current === 4 || level.current === 3)) return 'visible';
                if (_level === level.current) return 'visible';
                return 'none';
            }
            
            try {
                props.mapRef.current.addSource('country', {
                    type: 'geojson',
                    data: all_data[0],
                });
                props.mapRef.current.addSource('districts', {
                    type: 'geojson',
                    data: all_data[1],
                });
                props.mapRef.current.addSource('regions', {
                    type: 'geojson',
                    data: all_data[2],
                });
                props.mapRef.current.addSource('municipalities', {
                    type: 'geojson',
                    data: all_data[3],
                });
                props.mapRef.current.addSource('izbirkoms', {
                    type: 'geojson',
                    data: all_data[4],
                });
                props.mapRef.current.addSource('division', {
                    type: 'geojson',
                    data: all_data[5],
                });
                
                props.mapRef.current.addLayer({
                    id: 'country',
                    type: 'fill',
                    source: 'country',
                    layout: {
                        'visibility': getVisibility(0)
                    },
                    paint: {
                        'fill-color': ['get', 'color'],
                        'fill-opacity': 0.5
                    },
                });
                props.mapRef.current.addLayer({
                    id: 'districts',
                    type: 'fill',
                    source: 'districts',
                    layout: {
                        'visibility': getVisibility(1)
                    },
                    paint: {
                        'fill-color': ['get', 'color'],
                        'fill-opacity': 0.5
                    },
                });
                props.mapRef.current.addLayer({
                    id: 'regions',
                    type: 'fill',
                    source: 'regions',
                    layout: {
                        'visibility': getVisibility(2)
                    },
                    paint: {
                        'fill-color': ['get', 'color'],
                        'fill-opacity': 0.5
                    },
                });
                props.mapRef.current.addLayer({
                    id: 'izbirkoms',
                    type: 'fill',
                    source: 'izbirkoms',
                    layout: {
                        'visibility': getVisibility(4)
                    },
                    paint: {
                        'fill-color': ['get', 'color'],
                        'fill-opacity': 0
                    },
                });
                props.mapRef.current.addLayer({
                    id: 'background-regions',
                    type: 'fill',
                    source: 'regions',
                    layout: {
                        'visibility': getVisibility(5)
                    },
                    paint: {
                        'fill-color': ['get', 'color'],
                        'fill-opacity': 0.5
                    },
                });
                props.mapRef.current.addLayer({
                    id: 'background-muns',
                    type: 'fill',
                    source: 'division',
                    layout: {
                        'visibility': getVisibility(3)
                    },
                    paint: {
                        'fill-color': ['get', 'color'],
                        'fill-opacity': 0
                    },
                });
                props.mapRef.current.addLayer({
                    id: 'municipalities',
                    type: 'fill',
                    source: 'municipalities',
                    layout: {
                        'visibility': getVisibility(3)
                    },
                    paint: {
                        'fill-color': ['get', 'color'],
                        'fill-opacity': 0.5
                    },
                });
                
                props.mapRef.current.addLayer({
                    id: 'country-outline',
                    type: 'line',
                    source: 'country',
                    layout: {
                        'visibility': getVisibility(0)
                    },
                    paint: {
                        'line-color': [
                            'case',
                            ['boolean', ['feature-state', 'click'], false],
                            '#0441ec',
                            ['get', 'color'],
                        ],
                        'line-width': [
                            'case',
                            ['boolean', ['feature-state', 'click'], false],
                            2,
                            1
                        ],
                        'line-offset': [
                            'case',
                            ['boolean', ['feature-state', 'click'], false],
                            2,
                            1
                        ]
                    }
                });
                props.mapRef.current.addLayer({
                    id: 'districts-outline',
                    type: 'line',
                    source: 'districts',
                    layout: {
                        'visibility': getVisibility(1)
                    },
                    paint: {
                        'line-color': [
                            'case',
                            ['boolean', ['feature-state', 'click'], false],
                            '#0441ec',
                            ['get', 'color'],
                        ],
                        'line-width': [
                            'case',
                            ['boolean', ['feature-state', 'click'], false],
                            2,
                            1
                        ],
                        'line-offset': [
                            'case',
                            ['boolean', ['feature-state', 'click'], false],
                            2,
                            1
                        ]
                    }
                });
                props.mapRef.current.addLayer({
                    id: 'regions-outline',
                    type: 'line',
                    source: 'regions',
                    layout: {
                        'visibility': getVisibility(2)
                    },
                    paint: {
                        'line-color': [
                            'case',
                            ['boolean', ['feature-state', 'click'], false],
                            '#0441ec',
                            ['get', 'color'],
                        ],
                        'line-width': [
                            'case',
                            ['boolean', ['feature-state', 'click'], false],
                            2,
                            1
                        ],
                        'line-offset': [
                            'case',
                            ['boolean', ['feature-state', 'click'], false],
                            2,
                            1
                        ]
                    }
                });
                props.mapRef.current.addLayer({
                    id: 'municipalities-outline',
                    type: 'line',
                    source: 'municipalities',
                    layout: {
                        'visibility': getVisibility(3)
                    },
                    paint: {
                        'line-color': [
                            'case',
                            ['boolean', ['feature-state', 'click'], false],
                            '#0441ec',
                            ['get', 'color'],
                        ],
                        'line-width': [
                            'case',
                            ['boolean', ['feature-state', 'click'], false],
                            2,
                            1
                        ],
                        'line-offset': [
                            'case',
                            ['boolean', ['feature-state', 'click'], false],
                            2,
                            1
                        ]
                    }
                });
                props.mapRef.current.addLayer({
                    id: 'izbirkoms-outline',
                    type: 'line',
                    source: 'izbirkoms',
                    layout: {
                        'visibility': getVisibility(4)
                    },
                    paint: {
                        'line-color': [
                            'case',
                            ['boolean', ['feature-state', 'click'], false],
                            '#0441ec',
                            ['get', 'color'],
                        ],
                        'line-width': [
                            'case',
                            ['boolean', ['feature-state', 'click'], false],
                            2,
                            1
                        ],
                        'line-offset': [
                            'case',
                            ['boolean', ['feature-state', 'click'], false],
                            2,
                            1
                        ]
                    }
                });
                props.mapRef.current.addLayer({
                    id: 'background-regions-outline',
                    type: 'line',
                    source: 'division',
                    layout: {
                        'visibility': getVisibility(3)
                    },
                    paint: {
                        'line-color': ['get', 'color'],
                        'line-width': 1,
                    }
                });
            } catch (e: any) { }
        });
        
        props.mapRef.current.on('idle', () => {
            if (
                !props.mapRef.current.getLayer('country') ||
                !props.mapRef.current.getLayer('districts') ||
                !props.mapRef.current.getLayer('regions') ||
                !props.mapRef.current.getLayer('municipalities')
            ) {
                return;
            }
        });
        
        props.mapRef.current.on('click', addingLayerName[0].fill, (e) => {
            props.setLevel(2);
            if (props.compare_mode)
                props.setCompareName(e.features[0].properties.name);
            else
                props.setName(e.features[0].properties.name);
            setClickedRegion(e.features[0]);
        });
        
        props.mapRef.current.on('click', addingLayerName[1].fill, (e) => {
            props.setLevel(e.features[0].properties.level);
            
            setClicked3(e.features[0]);
            
            if (props.compare_mode) {
                props.setCompareName(e.features[0].properties.name);
                props.setCompareRegion(e.features[0].properties.a_rgn);
            } else {
                props.setName(e.features[0].properties.name);
                props.setRegion(e.features[0].properties.a_rgn);
            }
        });
        
        props.mapRef.current.on('click', toggleBarsLayersIds, (e) => {
            setClicked(e.features[0]);
        });
        
        props.mapRef.current.on('click', 'temperature', (e) => {
            props.setName(e.features[0].properties.name);
        })
        
        props.mapRef.current.on('mousemove', [...toggleBarsLayersIds, addingLayerName[0].fill, addingLayerName[1].fill, addingLayerName[1].added_bg, 'background-muns'], async (e) => {
            if (popup.current != null) {
                popup.current.setLngLat(e.lngLat);
                if (props.tempMode) {
                    let value = '';
                    for (const key1 in extraDataRowsNames)
                        for (const key2 in extraDataRowsNames[key1])
                            if (extraDataRowsNames[key1][key2].name === props.temperature_param) {
                                value = extraDataRowsNames[key1][key2].value;
                                break;
                            }
                    if (props.temperature !== null)
                        popup.current.setHTML(`
                            <h2>${ e.features[0].properties.name }</h2>
                            <p>${ props.temperature_param }: <b style="color: #2A5CDC">${ Math.round((JSON.parse(props.temperature)[e.features[0].properties.name] - 100) * 100) / 100 } ${ value }</b></p>
                        `);
                    else
                        popup.current.setHTML(`
                            <h3>${ e.features[0].properties.name }</h3>
                       `);
                } else if (props.popupMode && e.features[0].properties.name != name)
                    setName(e.features[0].properties);
                else if (e.features[0].layer.id !== 'background-muns' && e.features[0].layer.id !== addingLayerName[1].added_bg)
                    popup.current.setHTML(`${ e.features[0].properties.name }`);
                else
                    popup.current.setHTML(`<i>${ e.features[0].properties.name }</i>`);
            }
        });
        
        props.mapRef.current.on('mousemove', ['temperature'], async (e) => {
        
        })
        
        props.mapRef.current.on('mouseenter', [...toggleBarsLayersIds, addingLayerName[0].fill, addingLayerName[1].fill, addingLayerName[1].added_bg, 'background-muns'], (e) => {
            props.mapRef.current.getCanvas().style.cursor = 'pointer';
            if (popup.current === null) {
                popup.current = new mapboxgl.Popup({
                    closeOnClick: false,
                    closeButton: false,
                })
                    .setLngLat(e.lngLat)
                    .setHTML(e.features[0].properties.name)
                    .addTo(props.mapRef.current);
            }
        });
        
        props.mapRef.current.on('mouseleave', [...toggleBarsLayersIds, addingLayerName[0].fill, addingLayerName[1].fill, addingLayerName[1].added_bg, 'background-muns'], () => {
            props.mapRef.current.getCanvas().style.cursor = '';
            popup.current.remove();
            popup.current = null;
        });
        
        return () => {
            props.mapRef.current.remove();
        }
    }
    
    const getApiData = async (name: string, region: string, level: number) => {
        const api = new Api();
        let res: any;
        if (level === 0)
            res = await api.get_country();
        else if (level === 1)
            res = await api.get_district(name);
        else if (level === 2)
            res = await api.get_region(name);
        else if (level === 3)
            res = await api.get_municipality(name, region);
        else if (level == 4)
            res = await api.get_izbirkom(name);
        if (res)
            setInfo(JSON.parse(res));
    }
    
    useEffect(() => {
        level.current = props.level;
        if (props.showType && props.level === 3) {
            level.current = 4;
            props.setLevel(level.current)
        }
        if (!props.showType && props.level === 4) {
            level.current = 3;
            props.setLevel(level.current)
        }
        if (props.tempMode) {
            level.current = 2;
            props.setLevel(2);
        }
        
        if (props.all_data === null) return;
        for (let i = 0; i < 6; i++)
            all_data.push(parseGeojson(i, geobuf.decode(new Pbf(props.all_data[i]))));
        
        if (props.tempMode) {
            let _level = 1;
            const temp = JSON.parse(props.temperature);
            for (const region in temp) {
                if (region === "Центральный федеральный округ") {
                    _level = 1;
                    break;
                } else _level = 2;
            }
            
            all_data[_level] = setTemperature(temp, all_data[_level]);
        }
        loadMap();
    }, []);
    
    useEffect(() => {
        if (clicked3 === null) return;
        // zoom into
        const bounds = new mapboxgl.LngLatBounds();
        for (const coordinates of clicked3.geometry.coordinates) {
            for (const coord of coordinates) {
                if (isNaN(coord[0]))
                    for (const element of coord) {
                        let c = element;
                        c[0] = Number(c[0]);
                        c[1] = Number(c[1]);
                        bounds.extend(c);
                    }
                else {
                    let c = coord;
                    c[0] = Number(c[0]);
                    c[1] = Number(c[1]);
                    bounds.extend(c);
                }
            }
        }
        props.mapRef.current.fitBounds(bounds, {
            padding: 300,
        });
    }, [clicked3]);
    
    useEffect(() => {
        if (clickedRegion === null) return;
        
        // removing previous data
        for (const key in addingLayerName[1])
            if (props.mapRef.current.getLayer(addingLayerName[1][key]))
                props.mapRef.current.removeLayer(addingLayerName[1][key]);
        props.mapRef.current.setFilter(addingLayerName[0].fill, [
            'all',
            ['==', ['get', 'admin_l3'], clicked.properties.name],
            ['!=', ['get', 'name'], clickedRegion.properties.name]
        ]);
        
        // adding inside highlight
        props.mapRef.current.addLayer({
            id: addingLayerName[1].fill,
            type: 'fill',
            source: props.showType ? 'izbirkoms' : 'municipalities',
            layout: {},
            paint: {
                'fill-color': ['get', 'color'],
                'fill-opacity': props.showType ? 0.3 : 1
            },
            filter: ['==', 'a_rgn', clickedRegion.properties.name]
        });
        if (!props.showType) {
            props.mapRef.current.addLayer({
                id: addingLayerName[1].background,
                type: 'fill',
                source: 'regions',
                layout: {},
                paint: {
                    'fill-color': ['get', 'color'],
                    'fill-opacity': 0.3,
                },
                filter: ['==', 'name', clickedRegion.properties.name]
            });
            props.mapRef.current.addLayer({
                id: addingLayerName[1].added_bg,
                type: 'fill',
                source: 'division',
                layout: {},
                paint: {
                    'fill-color': ['get', 'color'],
                    'fill-opacity': 0
                },
                filter: ['==', 'a_rgn', clickedRegion.properties.name]
            }, 'municipalities');
            props.mapRef.current.addLayer({
                id: addingLayerName[1].background_outline,
                type: 'line',
                source: 'division',
                layout: {},
                paint: {
                    'line-color': ['get', 'color'],
                    'line-width': 1,
                },
                filter: ['==', 'a_rgn', clickedRegion.properties.name]
            });
            props.mapRef.current.moveLayer('municipalities');
        }
        props.mapRef.current.addLayer({
            id: addingLayerName[1].outline,
            type: 'line',
            source: props.showType ? 'izbirkoms' : 'municipalities',
            layout: {},
            paint: {
                'line-color': ['get', 'color'],
                'line-width': 1,
            },
            filter: ['==', 'a_rgn', clickedRegion.properties.name]
        });
        
        // zooming into
        const bounds = new mapboxgl.LngLatBounds();
        for (const coordinates of clickedRegion.geometry.coordinates) {
            for (const coord of coordinates) {
                if (isNaN(coord[0]))
                    for (const element of coord) {
                        let c = element;
                        c[0] = Number(c[0]);
                        c[1] = Number(c[1]);
                        bounds.extend(c);
                    }
                else {
                    let c = coord;
                    c[0] = Number(c[0]);
                    c[1] = Number(c[1]);
                    bounds.extend(c);
                }
            }
        }
        props.mapRef.current.fitBounds(bounds, {
            padding: 300,
        });
    }, [clickedRegion]);
    
    useEffect(() => {
        if (clicked === null) return;
        props.setLevel(clicked.properties.level);
        
        // removing all regions and municipalities layers
        for (const layers_group of addingLayerName)
            for (const key in layers_group)
                if (props.mapRef.current.getLayer(layers_group[key]))
                    props.mapRef.current.removeLayer(layers_group[key]);
        props.mapRef.current.setFilter('districts', null);
        props.mapRef.current.setFilter('regions', null);
        
        if (!props.compare_mode && !props.popupMode)
            props.setName(clicked.properties.name);
        else if (!props.popupMode)
            props.setCompareName(clicked.properties.name);
        
        // zooming into a region
        const bounds = new mapboxgl.LngLatBounds();
        for (const coordinates of clicked.geometry.coordinates) {
            for (const coord of coordinates) {
                if (isNaN(coord[0]))
                    for (const element of coord) {
                        let c = element;
                        c[0] = Number(c[0]);
                        c[1] = Number(c[1]);
                        bounds.extend(c);
                    }
                else {
                    let c = coord;
                    c[0] = Number(c[0]);
                    c[1] = Number(c[1]);
                    bounds.extend(c);
                }
            }
        }
        props.mapRef.current.fitBounds(bounds, {
            padding: 300,
        });
        
        if (props.tempMode) return;
        
        // highlighting an active layer
        if (clickedPolygon.current !== null) {
            props.mapRef.current.setFeatureState(
                { source: activeLayerId, id: clickedPolygon.current },
                { click: false }
            );
        }
        clickedPolygon.current = clicked.id;
        props.mapRef.current.setFeatureState(
            { source: activeLayerId, id: clickedPolygon.current },
            { click: true }
        );
        
        // showing all parts of a region
        if (level.current === 1 && !props.compare_mode) {
            // admin_l3
            props.mapRef.current.addLayer({
                id: addingLayerName[0].fill,
                type: 'fill',
                source: 'regions',
                layout: {},
                paint: {
                    'fill-color': ['get', 'color'],
                    'fill-opacity': 0.5
                },
                filter: ['==', 'admin_l3', clicked.properties.name]
            });
            props.mapRef.current.addLayer({
                id: addingLayerName[0].outline,
                type: 'line',
                source: 'regions',
                layout: {},
                paint: {
                    'line-color': ['get', 'color'],
                    'line-width': 1,
                },
                filter: ['==', 'admin_l3', clicked.properties.name]
            });
            props.mapRef.current.setFilter('districts', ['!=', ['get', 'name'], clicked.properties.name]);
        }
        if (level.current === 2 && !props.compare_mode) {
            // a_rgn
            props.mapRef.current.addLayer({
                id: addingLayerName[1].fill,
                type: 'fill',
                source: props.showType ? 'izbirkoms' : 'municipalities',
                layout: {},
                paint: {
                    'fill-color': ['get', 'color'],
                    'fill-opacity': props.showType ? 0.3 : 1
                },
                filter: ['==', 'a_rgn', clicked.properties.name]
            });
            if (!props.showType) {
                props.mapRef.current.addLayer({
                    id: addingLayerName[1].background,
                    type: 'fill',
                    source: 'regions',
                    layout: {},
                    paint: {
                        'fill-color': ['get', 'color'],
                        'fill-opacity': 0.3
                    },
                    filter: ['==', 'name', clicked.properties.name]
                });
                props.mapRef.current.addLayer({
                    id: addingLayerName[1].added_bg,
                    type: 'fill',
                    source: 'division',
                    layout: {},
                    paint: {
                        'fill-color': '#000000',
                        'fill-opacity': 0
                    },
                    filter: ['==', 'a_rgn', clicked.properties.name]
                }, 'municipalities');
                props.mapRef.current.addLayer({
                    id: addingLayerName[1].background_outline,
                    type: 'line',
                    source: 'division',
                    layout: {},
                    paint: {
                        'line-color': ['get', 'color'],
                        'line-width': 1,
                    },
                    filter: ['==', 'a_rgn', clicked.properties.name]
                });
                props.mapRef.current.moveLayer('municipalities');
            }
            props.mapRef.current.addLayer({
                id: addingLayerName[1].outline,
                type: 'line',
                source: props.showType ? 'izbirkoms' : 'municipalities',
                layout: {},
                paint: {
                    'line-color': ['get', 'color'],
                    'line-width': 1,
                },
                filter: ['==', 'a_rgn', clicked.properties.name]
            });
            props.mapRef.current.setFilter('regions', ['!=', ['get', 'name'], clicked.properties.name]);
        }
    }, [clicked]);
    
    useEffect(() => {
        if (name === null) return;
        getApiData(name.name, name.a_rgn, name.level).then();
    }, [name]);
    
    useEffect(() => {
        if (info === null) return;
        const round = (x: number) => {
            return Math.round(x * 100) / 100;
        }

        if (popup.current !== null && info.name === name.name)
            popup.current.setHTML(`
                <h2>${ info.name }</h2>
                ${ props.showType
                ? `${ ((props.popupData & (1 << 0)) === (1 << 0) && round(info.data['izbirkom']['total_izbir_people'])) ? `<p>Численность избирателей: ${ round(info.data['izbirkom']['total_izbir_people']) } ${ popupData[0].count }</p>` : `` }`
                : `
                    ${ ((props.popupData & (1 << 0)) === (1 << 0) && !isNaN(round(info.data[popupData[0].en]))) ? `<p>${ popupData[0].ru }: ${ round(info.data[popupData[0].en]) } ${ popupData[0].count }</p>` : `` }
                    ${ ((props.popupData & (1 << 1)) === (1 << 1) && !isNaN(round(info.data[popupData[1].en]))) ? `<p>${ popupData[1].ru }: ${ round(info.data[popupData[1].en]) } ${ popupData[1].count }</p>` : `` }
                    ${ ((props.popupData & (1 << 2)) === (1 << 2) && !isNaN(round(info.data[popupData[2].en]))) ? `<p>${ popupData[2].ru }: ${ round(info.data[popupData[2].en]) } ${ popupData[2].count }</p>` : `` }
                    ${ ((props.popupData & (1 << 3)) === (1 << 3) && !isNaN(round(info.data[popupData[3].en]))) ? `<p>${ popupData[3].ru }: ${ round(info.data[popupData[3].en]) } ${ popupData[3].count }</p>` : `` }
                    ${ ((props.popupData & (1 << 4)) === (1 << 4) && !isNaN(round(info.data[popupData[4].en]))) ? `<p>${ popupData[4].ru }: ${ round(info.data[popupData[4].en]) } ${ popupData[4].count }</p>` : `` }
                ` }
            `);
    }, [info]);
    
    useEffect(() => {
        props.activeMapLayerId.current = activeLayerId;
        if (!mapLoaded) return;
        
        // removing highlight from layers
        if (clickedPolygon.current !== null && prevActiveLayer.current !== null) {
            props.mapRef.current.setFeatureState(
                { source: prevActiveLayer.current, id: clickedPolygon.current },
                { click: false }
            );
        }
        
        // removing temperature layer
        props.setTemperature(null);
        if (props.mapRef.current.getLayer('temperature')) {
            props.mapRef.current.removeLayer('temperature');
            props.mapRef.current.removeSource('temperature');
        }
        
        // removing all adding layers
        for (const layers_group of addingLayerName)
            for (const key in layers_group)
                if (props.mapRef.current.getLayer(layers_group[key]))
                    props.mapRef.current.removeLayer(layers_group[key]);
        props.mapRef.current.setFilter('districts', null);
        props.mapRef.current.setFilter('regions', null);
        
        if (clickedPolygon.current !== null) {
            props.mapRef.current.setFeatureState(
                { source: activeLayerId, id: clickedPolygon.current },
                { click: false }
            );
        }
        clickedPolygon.current = null;
        
        toggleBarsLayersIds.forEach((layerId) => {
            if (activeLayerId === layerId) {
                props.mapRef.current.setLayoutProperty(layerId, 'visibility', 'visible');
                props.mapRef.current.setLayoutProperty(layerId + '-outline', 'visibility', 'visible');
            } else {
                props.mapRef.current.setLayoutProperty(layerId, 'visibility', 'none');
                props.mapRef.current.setLayoutProperty(layerId + '-outline', 'visibility', 'none');
            }
        });
        
        if (activeLayerId === 'municipalities' || activeLayerId === 'izbirkoms')
            props.mapRef.current.setLayoutProperty('background-regions', 'visibility', 'visible');
        else
            props.mapRef.current.setLayoutProperty('background-regions', 'visibility', 'none');
        
        if (activeLayerId === 'municipalities') {
            props.mapRef.current.setLayoutProperty('background-regions-outline', 'visibility', 'visible');
            props.mapRef.current.setLayoutProperty('background-muns', 'visibility', 'visible');
        } else {
            props.mapRef.current.setLayoutProperty('background-regions-outline', 'visibility', 'none');
            props.mapRef.current.setLayoutProperty('background-muns', 'visibility', 'none');
        }
        
        // updating prev active layer
        prevActiveLayer.current = activeLayerId;
    }, [activeLayerId]);
    
    const handleClick = (layerId) => {
        if (layerId === activeLayerId) return;
        
        setActiveLayerId(layerId);
        level.current = toggleBarsLayersIds.indexOf(layerId);
        props.setLevel(toggleBarsLayersIds.indexOf(layerId));
    }
    
    return (<>
        <>
            <div ref={ mapContainer } className="map-container"/>
            
            { props.tempMode ? null :
                <div
                    className="TopMenuContainer"
                    style={ { top: '100px', right: '10px' } }
                >
                    <div className="flex-row-rev just-space w-full align-center gap-10">
                        <input
                            onChange={ () => handleClick(toggleBarsLayersIds[0]) }
                            checked={ activeLayerId === toggleBarsLayersIds[0] }
                            type={ "checkbox" }
                        />
                        <p>Страна</p>
                    </div>
                    <div className="flex-row-rev just-space w-full align-center gap-10">
                        <input
                            onChange={ () => handleClick(toggleBarsLayersIds[1]) }
                            checked={ activeLayerId === toggleBarsLayersIds[1] }
                            type={ "checkbox" }
                        />
                        <p>Федеральные округа</p>
                    </div>
                    <div className="flex-row-rev just-space w-full align-center gap-10">
                        <input
                            onChange={ () => handleClick(toggleBarsLayersIds[2]) }
                            checked={ activeLayerId === toggleBarsLayersIds[2] }
                            type={ "checkbox" }
                        />
                        <p>Регионы</p>
                    </div>
                    { !props.showType
                        ?
                        <div className="flex-row-rev just-space w-full align-center gap-10">
                            <input
                                onChange={ () => handleClick(toggleBarsLayersIds[3]) }
                                checked={ activeLayerId === toggleBarsLayersIds[3] }
                                type={ "checkbox" }
                            />
                            <p>Муниципалитеты</p>
                        </div>
                        :
                        <div className="flex-row-rev just-space w-full align-center gap-10">
                            <input
                                onChange={ () => handleClick(toggleBarsLayersIds[4]) }
                                checked={ activeLayerId === toggleBarsLayersIds[4] }
                                type={ "checkbox" }
                            />
                            <p>Избирательные округа</p>
                        </div>
                    }
                </div>
            }
            
            { props.tempMode ? null :
                <TopMenu
                    type={ props.showType }
                    setShowType={ props.setShowType }
                />
            }
            
            { props.tempMode || props.popupMode ? null :
                <CrossMenu
                    key={ 'cross-menu' + compare_mode }
                    compare_mode={ compare_mode }
                    change_compare_mode={ setCompareMode }
                    change_state={ props.setCompareModeMain }
                />
            }
        </>
    </>);
});

export default Map;
