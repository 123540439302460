import React, { useRef } from "react";
import { extraDataNames, extraDataRowsNames } from "../../models/table-data-params";
import { LittleOpenMenu } from "../";
import './extra-data-menu.scss'

interface ExtraDataProp {
    mapRef: any;
    regions_data: any;
    
    data: any;
    level: number;
    change_temperature: any;
    change_temperature_param: any;
    temp_mode: boolean;
    temperature_param: string;
    temp_open_menu: number;
    setTempOpenMenu: any;
}

const ExtraDataMenu = (props: ExtraDataProp) => {
    let extraDataRows = () => {
        let result = [];
        let id1 = 0;
        if (props.data['extra'] == undefined) return [];
        for (const key1 in props.data['extra']) {
            result.push({
                name: extraDataNames[key1],
                data: []
            });
            for (const key2 in props.data['extra'][key1]) {
                if (extraDataRowsNames[key1][key2].name === undefined) continue;
                result[id1].data.push(
                    {
                        name: extraDataRowsNames[key1][key2].name,
                        count: `${ round(props.data['extra'][key1][key2]) } ${ extraDataRowsNames[key1][key2].value }`,
                    }
                );
            }
            id1++;
        }
        return result;
    };
    
    const round = (number: number) => {
        return Math.round(number * 100) / 100;
    }
    
    const render = () => {
        return (<>
            <section style={ { display: 'flex', flexDirection: 'column', gap: '10px' } }>
                <p>Выберите показатель, чтобы просмотреть тепловую карту</p>
                { extraDataRows().map((el, index) =>
                    <LittleOpenMenu
                        mapRef={ props.mapRef }
                        regions_data={ props.regions_data }
                        temperature_param={ props.temperature_param }
                        key={ el.name }
                        name={ el.name }
                        data={ el.data }
                        index={ index }
                        difference={ false }
                        level={ props.level }
                        change_temperature={ props.change_temperature }
                        change_temperature_param={ props.change_temperature_param }
                        temp_mode={ props.temp_mode }
                        temp_open_menu={props.temp_open_menu}
                        setTempOpenMenu={props.setTempOpenMenu}
                    />
                ) }
            </section>
        </>);
    }
    
    return render();
}

export default ExtraDataMenu;
