// Данные любого административно территориального деления из API
// export interface ObjectData {
//     name: string;
//     data: Data;
// }
//
// export interface Data {
//     birth?:                 number;
//     build?:                 number;
//     extra?:                 { [key: string]: { [key: string]: number } };
//     trade?:                 number;
//     crimes?:                number;
//     events?:                number;
//     nazi?:                  { [key: string]: number };
//     invest?:                number;
//     outlay?:                number;
//     people?:                number;
//     salary?:                number;
//     square?:                number;
//     izbirkom?:              Izbirkom;
//     pol_vozr?:              { [key: string]: { [key: string]: number } };
//     revenues?:              number;
//     services?:              number;
//     education?:             { [key: string]: number };
//     household?:             number;
//     migration?:             number;
//     agriculture?:           number;
//     failed_organizations?:  number;
//     success_organizations?: number;
// }
//
// export interface Izbirkom {
//     party?:               string;
//     count_izbir?:         number;
//     guber_turnout?:       number;
//     gosdum_turnout?:      number;
//     election_results?:    { [key: string]: number };
//     president_turnout?:   number;
//     oblast_dum_turnout?:  number;
//     total_izbir_people?:  number;
//     oblast_sobr_turnout?: number;
// }

export default class Api {
    public base_url: string;
    public base_assets_url: string;
    
    constructor() {
        const url = 'https://back.maps.tw1.ru/';
        // const url = 'http://127.0.0.1:8000/'
        this.base_url = `${ url }api/`;
        this.base_assets_url = `${ url }assets/`;
    }
    
    get_endpoint(endpoint: string, data: string = '') {
        if (data == '')
            return this.base_url + endpoint + "/";
        else
            return this.base_url + endpoint + '/?' + data;
    }
    
    get_assets_endpoint(endpoint: string, data: string = '') {
        if (data == '')
            return this.base_assets_url + endpoint;
        else
            return this.base_assets_url + endpoint;
    }
    
    async get_all_districts(): Promise<string> {
        const res = await fetch(this.get_endpoint('district/all'));
        return res.text();
    }
    
    async get_all_regions(): Promise<string> {
        const res = await fetch(this.get_endpoint('region/all'));
        return res.text();
    }
    
    async get_all_municipalities(): Promise<string> {
        const res = await fetch(this.get_endpoint('municipality/all'));
        return res.text();
    }
    
    async get_all_izbirkoms(): Promise<string> {
        const res = await fetch(this.get_endpoint('izbirkom/all'));
        return res.text();
    }
    
    async get_country(): Promise<string> {
        const res = await fetch(this.get_endpoint('country'));
        return res.text();
    }
    
    async get_district(name: string): Promise<string> {
        try {
            let res = await fetch(this.get_endpoint('district', `name=${ name }`));
            return res.text();
        } catch (e) {
            return '<';
        }
    }
    
    async get_region(name: string): Promise<string> {
        try {
            const res = await fetch(this.get_endpoint('region', `name=${ name }`));
            return res.text();
        } catch (e) {
            return '<';
        }
    }
    
    async get_municipality(name: string, region: string): Promise<string> {
        try {
            let res: any;
            if (name == 'г. Москва' || name == 'г. Санкт-Петербург' || name == 'г. Севастополь')
                res = await fetch(this.get_endpoint('region', `name=${ name }`));
            else
                res = await fetch(this.get_endpoint('municipality', `name=${ name }&region=${ region }`));
            return res.text();
        } catch (e) {
            return '<';
        }
    }
    
    async get_izbirkom(name: string): Promise<string> {
        try {
            const res = await fetch(this.get_endpoint('izbirkom', `name=${ name }`));
            return res.text();
        } catch (e) {
            return '<';
        }
    }
    
    async get_country_geojson(): Promise<ArrayBuffer> {
        const res = await fetch(this.get_assets_endpoint('country.pbf'));
        return res.arrayBuffer();
    }
    
    async get_districts_geojson(): Promise<ArrayBuffer> {
        const res = await fetch(this.get_assets_endpoint('districts.pbf'));
        return res.arrayBuffer();
    }
    
    async get_regions_geojson(): Promise<ArrayBuffer> {
        const res = await fetch(this.get_assets_endpoint('regions.pbf'));
        return res.arrayBuffer();
    }
    
    async get_municipalities_geojson(): Promise<ArrayBuffer> {
        const res = await fetch(this.get_assets_endpoint('municipalities.pbf'));
        return res.arrayBuffer();
    }
    
    async get_izbirkom_geojson(): Promise<ArrayBuffer> {
        const res = await fetch(this.get_assets_endpoint('izbirkom.pbf'));
        return res.arrayBuffer();
    }
    
    async get_division_geojson(): Promise<ArrayBuffer> {
        const res = await fetch(this.get_assets_endpoint('division.pbf'));
        return res.arrayBuffer();
    }
    
    async get_district_temperature(cat: string, param: string): Promise<string> {
        const res = await fetch(this.get_endpoint('district/temperature', `cat=${ cat }&param=${ param }`));
        return res.text();
    }
    
    async get_region_temperature(cat: string, param: string): Promise<string> {
        const res = await fetch(this.get_endpoint('region/temperature', `cat=${ cat }&param=${ param }`));
        return res.text();
    }
    
    async get_region_presentation(name: string, compare_name: string = ''): Promise<string> {
        let res: any;
        if (compare_name === '')
            res = await fetch(this.get_endpoint('region/presentation', `name=${ name }`));
        else
            res = await fetch(this.get_endpoint('region/presentation', `name=${ name }&name2=${ compare_name }`));
        return res.text();
    }
    
    async get_district_presentation(name: string, compare_name: string = ''): Promise<string> {
        let res: any;
        if (compare_name === '')
            res = await fetch(this.get_endpoint('district/presentation', `name=${ name }`));
        else
            res = await fetch(this.get_endpoint('district/presentation', `name=${ name }&name2=${ compare_name }`));
        return res.text();
    }
}