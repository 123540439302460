import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import '../nazi-grid/nazi-grid.scss'
import { polVozrDataNode, polVozrGridProp } from "../../../models/pol-vozr-grid-params";

const PolVozrGrid = (props: polVozrGridProp) => {
    const round = (number: number) => {
        return Math.round(number * 100) / 100;
    }
    
    const parsePolVozrData = () => {
        let result: polVozrDataNode[] = [];
        let valid_keys = [
            'Моложе трудоспособного возраста',
            'Старше трудоспособного возраста',
            'Трудоспособного возраста',
            'Всего'
        ];
        let id = 0;
        for (const key in props.data) {
            if (valid_keys.includes(key)) {
                result.push({
                    id: id++,
                    name: key,
                    all: round(props.data[key]['Всего']),
                    men: round(props.data[key]['Мужчины']),
                    women: round(props.data[key]['Женщины']),
                });
                if (props.difference) {
                    result[result.length - 1]['dif_all'] = round(props.difference_data[key]['Всего']);
                    result[result.length - 1]['dif_men'] = round(props.difference_data[key]['Мужчины']);
                    result[result.length - 1]['dif_women'] = round(props.difference_data[key]['Женщины']);
                }
            }
        }
        return result;
    }
    
    const render = () => {
        const data = parsePolVozrData();
        
        function getColoredTableCell(value: number, display: boolean) {
            if (!display) return null;
            if (value > 0)
                return <TableCell align="right" sx={ { color: 'green' } }>{ value }</TableCell>
            return <TableCell align="right" sx={ { color: 'red' } }>{ value }</TableCell>
        }
        
        const round = (x: number) => Math.round(x * 100) / 100;
        
        return (
            <>
                <p className="moving_block__line">Возрастные категории:</p>
                <div>
                    <TableContainer className="scrolling-div" component={ Paper }>
                        <Table sx={ { width: 'fit-content', minWidth: '100%' } } aria-label="simple table">
                            <TableHead>
                                <TableRow sx={ { '& *': { fontWeight: 'bold!important' } } }>
                                    <TableCell sx={ { width: '40%' } }>Название</TableCell>
                                    <TableCell align="right">Всего (чел)</TableCell>
                                    { props.difference ? <TableCell align="right">Всего 2 (чел)</TableCell> : null }
                                    <TableCell align="right">Мужчин (чел)</TableCell>
                                    { props.difference ? <TableCell align="right">Мужчин 2 (чел)</TableCell> : null }
                                    <TableCell align="right">Женщин (чел)</TableCell>
                                    { props.difference ? <TableCell align="right">Женщин 2 (чел)</TableCell> : null }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { data.map((row: polVozrDataNode) => (
                                    <TableRow
                                        key={ 'pol-vozr-table-item' + row.name }
                                        sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
                                    >
                                        <TableCell component="th" scope="row">{ row.name }</TableCell>
                                        <TableCell align="right">{ row.all }</TableCell>
                                        { getColoredTableCell(round(row.all), props.difference) }
                                        <TableCell align="right">{ row.men }</TableCell>
                                        { getColoredTableCell(round(row.men), props.difference) }
                                        <TableCell align="right">{ row.women }</TableCell>
                                        { getColoredTableCell(round(row.women), props.difference) }
                                    </TableRow>
                                )) }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </>
        );
    }
    
    return render();
}

export default PolVozrGrid;
