import React, { useEffect, useRef } from "react";
import { extraDataRowsNames } from "../../models/table-data-params";
import Api from "../../api/api";
import './little-open-menu.scss'
import { setTemperature, toggleBarsLayersIds } from "../../models/utils";
import geobuf from "geobuf";
import Pbf from "pbf";
import mapboxgl from "mapbox-gl";

interface LittleOpenMenuProp {
    mapRef: any;
    regions_data: any;
    name: string;
    data: any;
    index: number;
    difference?: any;
    level: number;
    change_temperature: any;
    temperature_param: string;
    change_temperature_param: any;
    temp_mode: boolean
    temp_open_menu: any;
    setTempOpenMenu: any;
}

const LittleOpenMenu = (props: LittleOpenMenuProp) => {
    let cat: string = '';
    let param: string = '';
    let radioDivChosenStyle = {
        background: '#2a5cdc',
    }
    const TemperatureParam = useRef(null);
    const Temperature = useRef(null);
    const popup = useRef(null);
    
    const ParseElCount = (count: string) => {
        if (count[count.length - 1] == '%') {
            let number = Number(count.slice(0, count.length - 2));
            return `${ Math.round((number - 100) * 100) / 100 }%`
        }
        return count;
    }
    
    const getTemperature = async (name: string) => {
        if (!props.temp_mode) return;
        // getting temp data from api
        const [cat, param] = getCatParam(name);
        const api = new Api();
        const temp_data = JSON.parse(await api.get_region_temperature(cat, param))
        TemperatureParam.current = name;
        Temperature.current = temp_data;
        
        // getting geojson data
        let geojson_data = geobuf.decode(new Pbf(props.regions_data))
        geojson_data = setTemperature(temp_data, geojson_data, name);
        
        // hiding previous layers
        if (props.mapRef.current.getLayer('temperature')) {
            props.mapRef.current.removeLayer('temperature');
            props.mapRef.current.removeSource('temperature');
        }
        toggleBarsLayersIds.forEach((layerId) => {
            props.mapRef.current.setLayoutProperty(layerId, 'visibility', 'none');
            props.mapRef.current.setLayoutProperty(layerId + '-outline', 'visibility', 'none');
        });
        props.mapRef.current.setLayoutProperty('regions-outline', 'visibility', 'none');
        
        //adding temperature layer
        props.mapRef.current.addSource('temperature', {
            type: 'geojson',
            data: geojson_data
        })
        props.mapRef.current.addLayer({
            id: 'temperature',
            type: 'fill',
            source: 'temperature',
            layout: {},
            paint: {
                'fill-color': ['get', 'color'],
                'fill-opacity': 0.5
            },
        })
        
        // setting temperature name
        props.change_temperature(temp_data);
        props.change_temperature_param(name);
        
        // adding custom event for map
        props.mapRef.current.on('mousemove', ['temperature'], (e) => {
           handleMouseMoveEvent(e);
        });
        props.mapRef.current.on('mouseenter', ['temperature'], (e) => {
            props.mapRef.current.getCanvas().style.cursor = 'pointer';
            if (popup.current === null) {
                popup.current = new mapboxgl.Popup({
                    closeOnClick: false,
                    closeButton: false,
                })
                    .setLngLat(e.lngLat)
                    .setHTML(e.features[0].properties.name)
                    .addTo(props.mapRef.current);
            }
        });
        props.mapRef.current.on('mouseleave', ['temperature'], () => {
            props.mapRef.current.getCanvas().style.cursor = '';
            popup.current.remove();
            popup.current = null;
        });
        
    }
    
    const handleMouseMoveEvent = (e: any) => {
        if (popup.current !== null) {
            popup.current.setLngLat(e.lngLat);
            
            let value = '';
            for (const key1 in extraDataRowsNames)
                for (const key2 in extraDataRowsNames[key1])
                    if (extraDataRowsNames[key1][key2].name === TemperatureParam.current) {
                        value = extraDataRowsNames[key1][key2].value;
                        break;
                    }
            if (Temperature.current !== null)
                popup.current.setHTML(`
                            <h2>${ e.features[0].properties.name }</h2>
                            <p style="line-height: 100%; margin-top: 20px;">${ TemperatureParam.current }: <b style="color: #2A5CDC">${ Math.round(((Temperature.current)[e.features[0].properties.name] - (value === '%'? 100 : 0)) * 100) / 100 } ${ value }</b></p>
                        `);
            else
                popup.current.setHTML(`
                            <h3>${ e.features[0].properties.name }</h3>
                       `);
        }
    }
    
    const getDifSpan = (el: any) => {
        if (!props.difference) return null;
        return <><span className="light-blue">{ el.difference.data }</span> / <span
            className={ el.difference.diff < 0 ? 'difference_span__lower' : 'difference_span__upper' }>{ el.difference.diff }</span></>
    }
    
    const getCatParam = (name: string) => {
        for (const cat in extraDataRowsNames)
            for (const param in extraDataRowsNames[cat]) {
                if (extraDataRowsNames[cat][param].name === name) {
                    return [cat, param];
                }
            }
    }
    
    let content = props.data.map((el) => {
        if (!isNaN(Number(el.count.split(' ')[0])))
            return <div key={ 'div-el' + el.name } className="lbm-div" onClick={ () => getTemperature(el.name) }>
                { !props.temp_mode ? null :
                    <div className={ "radio-div" }>
                        <span style={ { ...(props.temperature_param === el.name && radioDivChosenStyle) } }/>
                    </div>
                }
                <p key={ 'little-open-men-p-' + el.name } className={ "lbm-line" }>{ el.name }: <span
                    style={ { 'cursor': 'pointer' } }>{ ParseElCount(el.count) }</span> { getDifSpan(el) }</p>
            </div>
        return
    });
    
    const render = () => {
        let button_chosen = props.temp_open_menu === props.index;
        return (
            <div className="little-moving-block">
                <input type="radio"
                       name="lmb-checkbox"
                       id={ 'lmb-checkbox' + props.index + props.name }
                       checked={ button_chosen }
                       onChange={ () => {
                           props.setTempOpenMenu(props.index);
                           button_chosen = !button_chosen;
                       } }
                       style={{ height: '50px' }}
                />
                <label htmlFor={ 'lbm-checkbox' + props.index + props.name } className="lmb-header">
                    <p>{ props.name } на 2024 год</p>
                    <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.05687 -4.25131e-05L15.365 7.54541L12.3676 7.54541L7.99898 1.97723L8.1655 2.03405L7.7443 2.03405L7.92063 1.97723L3.54215 7.54541L0.554621 7.54541L6.86274 -4.2705e-05L9.05687 -4.25131e-05Z"
                            fill="black"/>
                    </svg>
                </label>
                <div className="lmb-content">
                    { content }
                </div>
            </div>
        );
    }
    
    return render();
}

export default LittleOpenMenu;
