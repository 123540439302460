// stuff imports
import React, {Component, useEffect, useState} from "react";
import { ExtraDataMenu, OpenMenu, SvgCross } from "../";
import { OpenMenuProp } from "../../models/open-menu-models";
import { SideMenuState } from "../../models/side-menu-models";
import Api from "../../api/api";
import './side-menu.scss'
import { popupData } from "../../models/popupData";
import SideMenuContent from "../side-menu-content";
import openMenu from "../open-menu";

interface SideMenuProps {
    mapRef: any;
    regions_data: any;
    
    name: string;
    setName: any;
    region?: string;
    setRegion?: any;
    level: number;
    showType: boolean;
    temperature_param: string;
    
    compare_name?: string;
    setCompareName: any;
    compare_region?: string;
    setCompareRegion: any;
    difference: boolean;
    temperature: any;
    
    setTemperature: any;
    setTemperatureParam: any;
    temp_open_menu: number;
    setTempOpenMenu: any;
    
    last_name: string;
    setLastName: any;
    last_region: string;
    setLastRegion: any;
    
    last_compare_name: string;
    setLastCompareName: any;
    last_compare_region: string;
    setLastCompareRegion: any;
    
    popupMode: boolean;
    setPopupMode: any;
    popupsData: number;
    setPopupsData: any;
    
    closeSideMenu: boolean;
    setCloseSideMenu: any;
    
    openMenuState: boolean[];
    setOpenMenuState: any;
    
    tempMode: boolean;
    setTempMode: any;
}

const SideMenu = (props: SideMenuProps) => {
    let SideMenuContainerRef = React.createRef<HTMLDivElement>();
    let SideMenuContentRef = React.createRef<HTMLDivElement>();
    let SideMenuHeaderRef = React.createRef<HTMLParagraphElement>();
    let SideMenuHeaderContainerRef = React.createRef<HTMLDivElement>();
    let SideMenuButtonsContainer = React.createRef<HTMLDivElement>();
    const [open_menus_data, setOpenMenusData] = useState([
        {
            name: 'Паспорт региона',
            data: {},
            change_temperature: props.setTemperature,
            change_temperature_param: props.setTemperatureParam,
        },
        {
            name: 'Социально-экономические показатели',
            data: {},
            change_temperature: props.setTemperature,
            change_temperature_param: props.setTemperatureParam,
        },
        {
            name: 'Избирательные показатели',
            data: {},
            change_temperature: props.setTemperature,
            change_temperature_param: props.setTemperatureParam,
        },
    ]);

    useEffect(() => {
        async function SubscribeAPI() {
            let resp: string = '';
            let level = props.level;
            if (props.tempMode) level = 2;
            switch (level) {
                case (0):
                    resp = await api_service.get_country();
                    GetDataFromApi(resp);
                    break;
                case(1):
                    resp = await api_service.get_district(props.name);
                    if (resp[0] === '<') {
                        props.setName(props.last_name);
                        props.setRegion(props.last_region);
                    } else {
                        GetDataFromApi(resp);
                        if (props.compare_name != undefined && props.difference)
                            resp = await api_service.get_district(props.compare_name);
                            if (resp[0] === '<') {
                                props.setCompareName(props.last_compare_name);
                                props.setCompareRegion(props.last_compare_region);
                            } else
                                GetDifferenceRegionData(resp);
                    }
                    break;
                case(2):
                    resp = await api_service.get_region(props.name);
                    if (resp[0] === '<') {
                        props.setName(props.last_name);
                        props.setRegion(props.last_region);
                    } else {
                        GetDataFromApi(resp);
                        if (props.compare_name != undefined && props.difference)
                            resp = await api_service.get_region(props.compare_name);
                            if (resp[0] === '<') {
                                props.setCompareName(props.last_compare_name);
                                props.setCompareRegion(props.last_compare_region);

                            } else
                                GetDifferenceRegionData(resp);
                    }
                    break;
                case(3):
                    resp = await api_service.get_municipality(props.name, props.region);
                    if (resp[0] === '<') {
                        props.setName(props.last_name);
                        props.setRegion(props.last_region);
                    } else {
                        GetDataFromApi(resp);
                        if (props.compare_name != undefined && props.difference)
                            resp = await api_service.get_municipality(props.compare_name, props.compare_region)
                            if (resp[0] === '<') {
                                props.setCompareName(props.last_compare_name);
                                props.setCompareRegion(props.last_compare_region);

                            } else
                                GetDifferenceRegionData(resp);
                    }
                    break;
                case(4):
                    resp = await api_service.get_izbirkom(props.name);
                    if (resp[0] === '<') {
                        props.setName(props.last_name);
                        props.setRegion(props.last_region);
                    } else {
                        GetDataFromApi(resp);
                        if (props.compare_name != undefined && props.difference)
                            resp = await api_service.get_izbirkom(props.compare_name);
                            if (resp[0] === '<') {
                                props.setCompareName(props.last_compare_name);
                                props.setCompareRegion(props.last_compare_region);

                            } else
                                GetDifferenceRegionData(resp);
                    }
                    break;
            }
        }

        SubscribeAPI().then().catch(err => console.log(err));
    }, []);
    useEffect(() => {
        const void_variable = {};
        if (open_menus_data[0].data == void_variable) return;
        ReloadComponents();
    }, [open_menus_data]);

    const changeOpenMenusData = (index: number, value: any) => {
        let current_data = open_menus_data;
        current_data[index].data = value;
        setOpenMenusData(current_data);
    }
    const [differenceData, setDifferenceData] = useState({});
    let region_name: string;
    let api_service: Api = new Api();

    const [reloadKey, setReloadKeys] = useState(0);
    
    const round = (number: any, _level: any = 0) => {
        if (number === "…" || _level > 5) return 0;
        if (isNaN(Number(number))) {
            let result = {};
            for (const key in number)
                result[key] = round(number[key], _level + 1);
            return result;
        } else
            return Math.round(number * 100) / 100;
    }
    
    const DivideRegionsData = (container: any, data: any, dataKey: string) => {
        const first = [
            'nazi',
            'square',
            'pol_vozr',
            'education',
            'people',
            'migration',
            'household',
            'birth',
            'crimes',
        ];
        const second = [
            'agriculture',
            'build',
            'trade',
            'service',
            'invest',
            'success_organizations',
            'outlay',
            'salary',
        ];
        
        if (dataKey === 'nazi' || dataKey === 'education' || dataKey === 'pol_vozr') {
            container[0].data[dataKey] = data[dataKey];
            return;
        }
        if (dataKey === 'extra') {
            container[1].data[dataKey] = data[dataKey];
            return;
        }
        if (dataKey === 'izbirkom') {
            container[2].data['count_izbir'] = round(data['izbirkom']['count_izbir']);
            container[2].data['total_izbir_people'] = round(data['izbirkom']['total_izbir_people']);
            if (data['izbirkom']['party'] !== undefined)
                container[2].data['party'] = data['izbirkom']['party'];
            if (data['izbirkom']['president_turnout'] !== undefined)
                container[2].data['president_turnout'] = round(data['izbirkom']['president_turnout']);
            if (data['izbirkom']['gosdum_turnout'] !== undefined)
                container[2].data['gosdum_turnout'] = round(data['izbirkom']['gosdum_turnout']);
            if (data['izbirkom']['guber_turnout'] !== undefined)
                container[2].data['guber_turnout'] = round(data['izbirkom']['guber_turnout']);
            if (data['izbirkom']['soviet_republic_turnout'] !== undefined)
                container[2].data['soviet_republic_turnout'] = round(data['izbirkom']['soviet_republic_turnout']);
            if (data['izbirkom']['oblast_dum_turnout'] !== undefined)
                container[2].data['oblast_dum_turnout'] = round(data['izbirkom']['oblast_dum_turnout']);
            if (data['izbirkom']['oblast_sobr_turnout'] !== undefined)
                container[2].data['oblast_sobr_turnout'] = round(data['izbirkom']['oblast_sobr_turnout']);
            if (data['izbirkom']['election_results'] !== undefined)
                container[2].data['election_results'] = data['izbirkom']['election_results'];
            
            return;
        }
        if (dataKey === 'failed_organizations') container[1].data['success_organizations'] += round(data['failed_organizations']);
        if (dataKey === 'revenues') container[1].data['outlay'] += round(data['revenues']);
        
        for (const string of first) {
            if (dataKey === string) {
                container[0].data[dataKey] = round(data[dataKey]);
                return;
            }
        }
        
        for (const string of second) {
            if (dataKey === string) {
                container[1].data[dataKey] = round(data[dataKey]);
                return;
            }
        }
    }
    
    const ParseRegionData = (data: any) => {
        let container = open_menus_data;
        for (const dataKey in data) {
            DivideRegionsData(container, data, dataKey);
        }
        setOpenMenusData(container);
    }
    
    const ParseRegionDifferenceData = (data: any) => {
        let difference_data = round(data);
        difference_data['success_organizations'] += difference_data['failed_organizations']
        difference_data['outlay'] += difference_data['revenues'];
        
        return difference_data;
    }
    
    const GetDataFromApi = (resp: string) => {
        props.setLastName(props.name);
        props.setLastRegion(props.region);
        
        const data = JSON.parse(resp);
        region_name = data.name;
        ParseRegionData(data.data);
    }
    
    const GetDifferenceRegionData = (resp: string) => {
        props.setLastCompareName(props.compare_name);
        props.setLastCompareRegion(props.compare_region);
        
        const data = JSON.parse(resp);
        setDifferenceData(ParseRegionDifferenceData(data.data));
    }
    
    const ReloadComponents = () => {
        setReloadKeys(reloadKey ^ 1);
    }
    
    useEffect(() => setReloadKeys(reloadKey ^ 1), [differenceData]);
    
    return <SideMenuContent
        mapRef={ props.mapRef }
        regions_data={ props.regions_data }
        key={'side-menu-content' + reloadKey}
        name={props.name}
        level={props.level}
        showType={props.showType}
        temperature_param={props.temperature_param}
        difference={props.difference}
        setTemperature={props.setTemperature}
        setTemperatureParam={props.setTemperatureParam}
        temp_open_menu={props.temp_open_menu}
        setTempOpenMenu={props.setTempOpenMenu}
        popupMode={props.popupMode}
        setPopupMode={props.setPopupMode}
        popupsData={props.popupsData}
        setPopupsData={props.setPopupMode}
        closeSideMenu={props.closeSideMenu}
        setCloseSideMenu={props.setCloseSideMenu}
        openMenuState={props.openMenuState}
        setOpenMenuState={props.setOpenMenuState}
        tempMode={props.tempMode}
        setTempMode={props.setTempMode}
        open_menus_data={open_menus_data}
        difference_data={differenceData}
        compare_name={props.compare_name}
    />
}

export default SideMenu;
